$folioBlue: #505AFC;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(250, 253, 255, 1.0) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAutocomplete-root {
  .Mui-disabled {
    background-color: rgba(224, 224, 224, 1);
  }
}

.MuiFormControl-root {
    // Apply disabled styles only to input components, not affecting FormHelperText
    .MuiInputBase-root.Mui-disabled,
    .MuiSelect-root.Mui-disabled {
      background-color: rgba(224, 224, 224, 1);
    }
}

.minimal-input {
  background-color: rgba(0, 0, 0, 0);
  color: $folioBlue;
  border: none;

  .MuiFormControl-root {
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
  .MuiInputBase-input {
    font-weight: 600;
    color: $folioBlue;
    border: none;
    &:focus {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
    }
  }
  .MuiInputBase-root {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      border: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      border: none;
    }
  }

  .MuiSvgIcon-root {
    color: $folioBlue;
  }

  .MuiInputAdornment-root {
    color: $folioBlue;
  }

}