$folioBlue: '#505AFC';

.side_nav_bar {
    .Mui-selected .MuiTypography-root {
        font-weight: 600 !important;
        color: #ffffff !important;
        
    }
    
    .Mui-selected .MuiListItemIcon-root {
        color: $folioBlue;
    }
}

// .MuiListItem-root {
//     padding: 0 16px !important;
// }